import { func, number } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import {
  ArrowNextIcon,
  ArrowPrevIcon,
  Bullet,
  Bullets,
  Button,
  Buttons,
  NavigationArea,
} from './styles'

const propTypes = {
  activeItem: number,
  moveTo: func,
  moveToItem: func,
  total: number,
}

const Navigation = ({ activeItem, moveTo, moveToItem, total }) => {
  const { triggerEvent } = useTriggerEvents()

  const handleMoveToItem = index => {
    moveToItem(index)

    triggerEvent({
      label: `Dots | ${index + 1}`,
      pageInteraction: true,
    })
  }

  const handleMoveTo = direction => {
    moveTo(direction)

    const label = direction === 1 ? 'next' : 'prev'
    triggerEvent({
      label: `Arrow | ${label}`,
      pageInteraction: true,
    })
  }

  return total > 1 ? (
    <NavigationArea area="navigation" data-testid="navigation">
      <Bullets>
        {[...Array(total).keys()].map(index => (
          <Bullet
            key={index}
            active={activeItem === index}
            onClick={() => handleMoveToItem(index)}
            role="button"
            aria-label={`Mover para ${index + 1}`}
          />
        ))}
      </Bullets>

      <Buttons>
        <Button
          variant="transparent"
          hasOnlyIcon
          onClick={() => handleMoveTo(-1)}
        >
          <ArrowPrevIcon />
        </Button>
        <Button
          variant="transparent"
          hasOnlyIcon
          onClick={() => handleMoveTo(+1)}
        >
          <ArrowNextIcon />
        </Button>
      </Buttons>
    </NavigationArea>
  ) : null
}

Navigation.propTypes = propTypes

export { Navigation }
