import { array, number, oneOf, string } from 'prop-types'

import { motion } from 'framer-motion'

import { Animate, QuotesIcon, Text, Wrapper } from './styles'

const propTypes = {
  activeItem: number,
  backgroundColor: oneOf(['gray', 'dark']),
  icon: string,
  items: array,
}

const Texts = ({ activeItem, backgroundColor, icon, items }) => {
  const hidden = {
    opacity: 0,
  }

  const visible = {
    opacity: 1,
  }

  const transition = {
    type: 'spring',
    delay: 0,
    staggerChildren: 0.03,
  }

  const variants = {
    hidden,
    visible: isActive =>
      isActive
        ? {
            ...visible,
            transition,
          }
        : hidden,
  }

  const wordVariants = {
    hidden,
    visible: isActive => (isActive ? visible : hidden),
  }

  const icons = {
    quotes: QuotesIcon,
  }

  const Icon = icon ? icons[icon] : null
  const showIcon = items?.length && icon

  return (
    <Wrapper>
      {showIcon && <Icon />}

      {items?.map(({ name, text }, index) => (
        <Animate
          key={`${name}-${index}`}
          variants={variants}
          custom={activeItem === index}
          initial="hidden"
          animate="visible"
          hasIcon={showIcon}
        >
          <Text
            variant={{ _: 'bodyMdRegular', '5xl': 'bodyXlRegular' }}
            color={backgroundColor === 'dark' ? 'neutral.0' : 'neutral.90'}
            as="div"
          >
            {text?.split(' ').map((word, wordIndex) => (
              <motion.span
                key={`${word}-${wordIndex}`}
                variants={wordVariants}
                custom={activeItem === index}
              >
                {word}{' '}
              </motion.span>
            ))}
          </Text>
        </Animate>
      ))}
    </Wrapper>
  )
}

Texts.propTypes = propTypes

export { Texts }
