import { Button as CreditasButton } from '@creditas-lab/button'
import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { ArrowRightIcon } from 'components/Icons'

const NavigationArea = styled.div`
  grid-area: navigation;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Bullets = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.up('5xl')} {
    display: block;
  }
`

const Bullet = styled.div(
  ({ active, theme }) => css`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${selectTheme('colors.neutral.20')({ theme })};
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;

    &:hover {
      background-color: ${selectTheme('colors.primary.60')({ theme })};
    }

    ${active &&
    `
      background-color: ${selectTheme('colors.primary.60')({ theme })};
    `}
  `,
)

const Buttons = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;

  ${media.up('5xl')} {
    display: flex;
  }
`

const Button = styled(CreditasButton)(
  ({ theme }) => css`
    margin-left: 16px;

    &:hover svg {
      color: ${selectTheme('colors.neutral.0')({ theme })};
    }
  `,
)

const ArrowPrevIcon = styled(ArrowRightIcon)(
  ({ theme }) => css`
    color: ${selectTheme('colors.primary.60')({ theme })};
    transform: rotate(180deg);
  `,
)

const ArrowNextIcon = styled(ArrowRightIcon)(
  ({ theme }) => css`
    color: ${selectTheme('colors.primary.60')({ theme })};
  `,
)

export {
  NavigationArea,
  Bullets,
  Bullet,
  Buttons,
  Button,
  ArrowPrevIcon,
  ArrowNextIcon,
}
