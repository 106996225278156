import { styled } from '@creditas-ui/system'

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #000;
`

const Iframe = styled.iframe`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`

export { Wrapper, Iframe }
