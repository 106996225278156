import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as ContainerComponent } from 'components/Container'
import { Grid as UiGrid } from 'components/Grid'

const Wrapper = styled.section(
  ({ color, theme }) => css`
    background: ${selectTheme(
      `colors.neutral.${color === 'dark' ? '90' : '10'}`,
    )({ theme })};
  `,
)

const Container = styled(ContainerComponent)`
  position: relative;
  height: auto;
  min-height: auto;
  padding-top: 64px;
  padding-bottom: 64px;

  ${media.up('5xl')} {
    min-height: 683px;
    padding-top: 164px;
    padding-bottom: 164px;
  }
`

const Grid = styled(UiGrid)`
  grid-template-areas:
    '${'titles '.repeat(8)}'
    '${'carousel '.repeat(8)}'
    '${'texts '.repeat(8)}'
    '${'navigation '.repeat(8)}';

  ${media.up('5xl')} {
    grid-template-areas:
      '. ${'carousel '.repeat(5)} . ${'titles '.repeat(6)}'
      '. ${'carousel '.repeat(5)} . ${'texts '.repeat(6)}'
      '. ${'carousel '.repeat(5)} . ${'navigation '.repeat(6)}';
  }
`

export { Grid, Wrapper, Container }
