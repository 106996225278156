import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { motion } from 'framer-motion'

const Wrapper = styled.div`
  grid-area: titles;
  position: relative;
`

const Container = styled.div`
  position: relative;
  min-height: 125px;
`

const Animate = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
`

const PreTitle = styled(Typography)`
  margin-top: 64px;
  text-transform: uppercase;
`

const Title = styled(Typography)(
  ({ template }) => css`
    margin-top: 16px;

    ${template !== 'dark' &&
    css`
      text-transform: uppercase;
    `}

    span {
      width: 100%;
      display: block;
    }
  `,
)

const SubTitle = styled(Typography)`
  margin-top: 4px;
`

export { Wrapper, Container, Animate, PreTitle, Title, SubTitle }
