import { useRef } from 'react'

const useCarousel = () => {
  const ref = useRef()

  const slidePrev = () => ref.current.swiper.slidePrev()

  const slideNext = () => ref.current.swiper.slideNext()

  const slideTo = index => ref.current.swiper.slideToLoop(index)

  const refresh = () => {
    const carouselRef = ref.current?.swiper
    if (!carouselRef) return

    carouselRef.slideTo(0, 0)
    carouselRef.update()
    carouselRef.updateSize()
  }

  const slidesToRtl = () => {
    const carouselRef = ref.current?.swiper
    if (!carouselRef) return

    carouselRef.rtl = true
    carouselRef.rtlTranslate = true
  }

  const slidesToLtl = () => {
    const carouselRef = ref.current?.swiper
    if (!carouselRef) return

    carouselRef.rtl = false
    carouselRef.rtlTranslate = false
  }

  return [ref, slidePrev, slideNext, slideTo, refresh, slidesToRtl, slidesToLtl]
}

export { useCarousel }
