import { forwardRef, useEffect, useState } from 'react'
import { array, func, number } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { setResizeHandle } from 'ui/utils/setResizeHandle'
import debounce from 'utils/debounce'

import { Carousel as CarouselComponent } from 'components/Carousel'

import carouselOptions from '../../utils/carouselOptions'
import { CarouselCard } from '../CarouselCard'

import { Container, Wrapper } from './styles'

const propTypes = {
  activeItem: number,
  items: array,
  onSelect: func,
  onStopAnimation: func,
  refresh: func,
  slidesToLtl: func,
  slidesToRtl: func,
}

const Carousel = forwardRef((props, ref) => {
  const {
    activeItem,
    items,
    onSelect,
    onStopAnimation,
    refresh,
    slidesToLtl,
    slidesToRtl,
    ...others
  } = props

  const [dir, setDir] = useState()
  const { triggerEvent } = useTriggerEvents()

  const handleResize = ({ innerWidth }) => {
    if (innerWidth > 1024) {
      setDir('rtl')
      slidesToRtl()
    } else {
      setDir('ltl')
      slidesToLtl()
    }

    refresh()
  }

  useEffect(() => {
    setResizeHandle(handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const total = items?.length || 0

  const handleMoveScroll = index => {
    if (!items?.length) return

    triggerEvent({
      label: index,
      pageInteraction: true,
    })
  }

  const options = {
    ...carouselOptions,
    allowTouchMove: total > 1,
    onRealIndexChange: ({ realIndex }) => onSelect(realIndex),
    onSliderMove: ({ realIndex }) =>
      debounce(() => handleMoveScroll(realIndex + 1), 500),
  }

  return dir ? (
    <Wrapper {...others}>
      {total && (
        <Container total={total}>
          <CarouselComponent ref={ref} options={options} dir={dir}>
            {items.map(({ id, ...item }, index) => (
              <CarouselCard
                key={id}
                index={index}
                onStopAnimation={onStopAnimation}
                {...item}
              />
            ))}
          </CarouselComponent>
        </Container>
      )}
    </Wrapper>
  ) : null
})

Carousel.propTypes = propTypes
Carousel.displayName = 'Carousel'

export { Carousel }
