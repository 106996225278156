import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { PlayButton as PlayButtonIcon } from 'components/Icons'
import { LazyImage } from 'components/LazyImage'

const Wrapper = styled.div(
  ({ isActive }) => css`
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: #000;
      opacity: ${isActive ? 0 : 0.4};
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transition: opacity 0.4s ease-in-out;
    }
  `,
)

const Img = styled(LazyImage)`
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const PlayButton = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 64px;
  height: 64px;

  ${media.up('5xl')} {
    width: 120px;
    height: 120px;
  }

  &:hover {
    transform: scale(1.2);
    background: rgba(255, 255, 255, 0.2);
  }
`

const PlayIcon = styled(PlayButtonIcon)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

export { Wrapper, Img, PlayButton, PlayIcon }
