import 'swiper/swiper.min.css'

import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  position: relative;
  grid-area: carousel;
  height: 418px;

  ${media.up('5xl')} {
    height: 683px;
  }
`

const calcContainerWidth = total => (total > 1 ? total * 236 + 49 : '250')

const container = css`
  .swiper-container {
    height: 418px;
    width: 100%;
    padding-bottom: 56px;
    box-sizing: content-box;

    ${media.up('5xl')} {
      height: 683px;
    }
  }
`

const wrapper = css`
  .swiper-wrapper {
    align-items: flex-end;
  }
`

const slide = css`
  .swiper-slide {
    transition: all 0.6s ease-in-out;
    transform: translateZ(0);
    display: flex;
    position: relative;
    width: 236px;
    height: 236px;
    margin-left: 16px;

    ${media.up('5xl')} {
      width: 385px;
      height: 360px;
      margin-left: 24px;
    }

    &-duplicate-active,
    &-active {
      width: 236px;
      height: 418px;

      ${media.up('5xl')} {
        width: 385px;
        height: 683px;
      }
    }
  }
`

const Container = styled.div(
  ({ total }) => css`
    list-style: none;
    height: 100%;
    position: relative;
    right: 0%;
    bottom: 0;
    margin-left: -16px;
    width: calc(100% + 32px);
    max-width: ${calcContainerWidth(total)}px;

    ${media.up('5xl')} {
      position: absolute;
      margin-left: 0;
      width: ${total * 385}px;
      max-width: fit-content;
    }

    ${container};
    ${wrapper};
    ${slide};
  `,
)

export { Wrapper, Container }
