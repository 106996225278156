import { array, number, oneOf, string } from 'prop-types'

import {
  Animate,
  Container,
  PreTitle,
  SubTitle,
  Title,
  Wrapper,
} from './styles'

const propTypes = {
  activeItem: number,
  backgroundColor: oneOf(['gray', 'dark']),
  items: array,
  title: string,
  titleHeadingTag: string,
}

const Titles = ({
  activeItem,
  backgroundColor,
  items,
  title,
  titleHeadingTag,
}) => {
  const hidden = {
    opacity: 0,
  }

  const visible = {
    opacity: 1,
  }

  const variants = {
    hidden,
    visible: isActive => (isActive ? visible : hidden),
  }

  return (
    <Wrapper>
      {title && (
        <PreTitle
          variant={{ _: 'bodyXsBold', '5xl': 'bodySmBold' }}
          color={backgroundColor === 'dark' ? 'neutral.0' : 'neutral.60'}
          as={titleHeadingTag || 'p'}
        >
          {title}
        </PreTitle>
      )}

      <Container>
        {items?.map((item, index) => {
          const {
            subtitle,
            subtitleHeadingTag,
            title: itemTitle,
            titleHeadingTag: itemTitleHeadingTag,
          } = item

          return (
            <Animate
              key={index}
              variants={variants}
              custom={activeItem === index}
              initial="hidden"
              animate="visible"
            >
              {itemTitle && (
                <Title
                  variant={{
                    _: 'headingLgRegular',
                    '5xl': 'heading2xlRegular',
                  }}
                  color={
                    backgroundColor === 'dark' ? 'neutral.0' : 'neutral.90'
                  }
                  template={backgroundColor}
                  as={itemTitleHeadingTag || 'h2'}
                >
                  <span dangerouslySetInnerHTML={{ __html: itemTitle }} />
                </Title>
              )}

              {subtitle && (
                <SubTitle
                  variant={{ _: 'bodySmRegular', '5xl': 'bodyMdRegular' }}
                  color={
                    backgroundColor === 'dark' ? 'neutral.0' : 'neutral.60'
                  }
                  as={subtitleHeadingTag || 'p'}
                >
                  {subtitle}
                </SubTitle>
              )}
            </Animate>
          )
        })}
      </Container>
    </Wrapper>
  )
}

Titles.propTypes = propTypes

export { Titles }
