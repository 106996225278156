import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled.div(
  ({ isActive, theme }) => css`
    width: 100%;
    height: 100%;
    padding-left: 0;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    background: ${selectTheme('colors.primary.60')({ theme })};
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: ${isActive ? selectTheme('shadow.md')({ theme }) : 'none'};
  `,
)

export { Wrapper }
