import { string } from 'prop-types'

import { Iframe, Wrapper } from './styles'

const propTypes = {
  videoId: string,
}

const Video = ({ videoId, ...others }) => (
  <Wrapper {...others}>
    <Iframe
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&modestbranding=1`}
      allow="accelerometer; autoplay; gyroscope;"
      width="100%"
    />
  </Wrapper>
)

Video.propTypes = propTypes

export { Video }
