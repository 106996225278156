export default {
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  initial: 1,
  observer: true,
  loop: true,
  slideToClickedSlide: true,
  preventClicks: true,
  freeMode: true,
  freeModeMomentum: false,
  freeModeSticky: true,
  speed: 1400,
  breakpoints: {
    1024: {
      touchRatio: 0.4,
      grabCursor: true,
    },
  },
}
