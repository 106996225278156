/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import { array, oneOf, string } from 'prop-types'

import { useCarousel } from 'components/Carousel/hooks/useCarousel'

import { Carousel } from './components/Carousel'
import { Navigation } from './components/Navigation'
import { Texts } from './components/Texts'
import { Titles } from './components/Titles'

import { Container, Grid, Wrapper } from './styles'

const propTypes = {
  backgroundColor: oneOf(['gray', 'dark']),
  carouselImageVideoCards: array,
  icon: string,
  title: string,
  titleHeadingTag: string,
}

const CarouselImageVideo = ({
  backgroundColor,
  carouselImageVideoCards: items,
  icon,
  title,
  titleHeadingTag,
  ...others
}) => {
  const [activeItem, setActiveItem] = useState(0)
  const currentSection = useRef(null)
  backgroundColor = backgroundColor ?? 'gray'

  const [
    ref,
    slidePrev,
    slideNext,
    slideTo,
    refresh,
    slidesToRtl,
    slidesToLtl,
  ] = useCarousel()

  let animation
  const handleAnimation = useCallback(() => {
    if (!animation && items?.length > 1) {
      animation = setInterval(() => {
        setActiveItem(oldActive => oldActive++)
        slideNext()
      }, 6000)
    }
  }, [animation])

  const handleStopAnimation = useCallback(() => {
    clearInterval(animation)
    animation = null
  }, [animation])

  const handleSelect = index => setActiveItem(index)

  useEffect(() => {
    handleAnimation()
    return () => handleStopAnimation()
  }, [handleAnimation, handleStopAnimation])

  const moveTo = newDirection =>
    newDirection === 1 ? slideNext() : slidePrev()

  return (
    <Wrapper ref={currentSection} color={backgroundColor} {...others}>
      <Container>
        <Grid>
          <Carousel
            ref={ref}
            refresh={refresh}
            slidesToRtl={slidesToRtl}
            slidesToLtl={slidesToLtl}
            items={items}
            activeItem={activeItem}
            onSelect={handleSelect}
            onStopAnimation={handleStopAnimation}
          />

          <Titles
            title={title}
            titleHeadingTag={titleHeadingTag}
            items={items}
            activeItem={activeItem}
            backgroundColor={backgroundColor}
          />

          <Texts
            items={items}
            activeItem={activeItem}
            icon={icon}
            backgroundColor={backgroundColor}
          />

          <Navigation
            activeItem={activeItem}
            moveTo={moveTo}
            moveToItem={slideTo}
            total={items?.length || 0}
          />
        </Grid>
      </Container>
    </Wrapper>
  )
}

CarouselImageVideo.propTypes = propTypes

export default CarouselImageVideo
