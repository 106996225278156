import { useEffect, useState } from 'react'
import { bool, func, number, object, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Image } from '../Image'
import { Video } from '../Video'

import { Wrapper } from './styles'

const propTypes = {
  'data-testid': string,
  image: shape({
    description: string,
    fluid: object,
  }),
  index: number,
  isActive: bool,
  onStopAnimation: func,
  videoId: string,
}

const CarouselCard = ({
  'data-testid': dataTestId,
  image,
  index,
  isActive,
  onStopAnimation,
  videoId,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const { triggerEvent } = useTriggerEvents()

  useEffect(() => {
    setShowVideo(false)
  }, [isActive])

  const dispatchEvent = isPlayingVideo => {
    const type = isPlayingVideo ? 'play' : 'image'

    triggerEvent({
      label: `${index + 1} | ${image?.description} | ${type}`,
      pageInteraction: true,
    })
  }

  const handleClick = () => {
    const shouldShowVideo = videoId && !showVideo && isActive
    setShowVideo(shouldShowVideo)
    if (shouldShowVideo) onStopAnimation()

    dispatchEvent(shouldShowVideo)
  }

  return image ? (
    <Wrapper data-testid={dataTestId} onClick={handleClick} isActive={isActive}>
      {!showVideo && (
        <Image {...image} showPlayButton={!!videoId} isActive={isActive} />
      )}

      {showVideo && <Video videoId={videoId} />}
    </Wrapper>
  ) : null
}

CarouselCard.propTypes = propTypes

export { CarouselCard }
