import { bool, object, string } from 'prop-types'

import { Img, PlayButton, PlayIcon, Wrapper } from './styles'

const propTypes = {
  description: string,
  fluid: object,
  isActive: bool,
  showPlayButton: bool,
}

const Image = ({ description, fluid, isActive, showPlayButton, ...others }) => (
  <Wrapper isActive={isActive} {...others}>
    <Img imgStyle={{ objectPosition: 'top' }} {...fluid} alt={description} />

    {showPlayButton && (
      <PlayButton>
        <PlayIcon />
      </PlayButton>
    )}
  </Wrapper>
)

Image.propTypes = propTypes

export { Image }
