import React, { forwardRef } from 'react'
import { node, object, string } from 'prop-types'

import { Swiper, SwiperSlide } from 'swiper/react'

const propTypes = {
  children: node,
  dir: string,
  options: object,
}

const Carousel = forwardRef(({ children, dir, options, ...others }, ref) => (
  <Swiper ref={ref} {...options} {...others} dir={dir}>
    {React.Children.map(children, (child, index) => (
      <SwiperSlide key={index}>
        {({ isActive }) => React.cloneElement(child, { isActive })}
      </SwiperSlide>
    ))}
  </Swiper>
))

Carousel.propTypes = propTypes
Carousel.displayName = 'Carousel'

export { Carousel }
