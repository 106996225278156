import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'
import { motion } from 'framer-motion'

import { Quotes } from 'components/Icons'

const Wrapper = styled.div`
  grid-area: texts;
  position: relative;
  min-height: 220px;
  width: 100%;
  margin-top: 48px;

  ${media.up('5xl')} {
    min-height: 260px;
    width: 80%;
    margin-top: 64px;
  }
`

const Animate = styled(motion.div)(
  ({ hasIcon }) => css`
    width: 100%;
    height: calc(100% - 45px);
    position: absolute;
    left: 0;
    top: ${hasIcon ? '72px' : 0};

    ${media.up('5xl')} {
      top: 64px;
    }
  `,
)

const Text = styled(Typography)`
  position: relative;
`

const QuotesIcon = styled(Quotes)`
  margin-bottom: 24px;
`

export { Wrapper, QuotesIcon, Animate, Text }
